define("discourse/plugins/discourse-dev-additions/discourse/templates/connectors/user-custom-preferences/user-manager", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">
      {{i18n "discourse_dev_additions.manager.title"}}
    </label>
    <div class="controls">
      {{combo-box
        content=this.allManagers
        value=this.userManager
        valueProperty=null
        nameProperty=null
        class="user-manager"
        none="discourse_dev_additions.manager.none"
      }}
    </div>
  </div>
  */
  {
    "id": "ltACUu3A",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"discourse_dev_additions.manager.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"content\",\"value\",\"valueProperty\",\"nameProperty\",\"class\",\"none\"],[[30,0,[\"allManagers\"]],[30,0,[\"userManager\"]],null,null,\"user-manager\",\"discourse_dev_additions.manager.none\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"combo-box\"]]",
    "moduleName": "discourse/plugins/discourse-dev-additions/discourse/templates/connectors/user-custom-preferences/user-manager.hbs",
    "isStrictMode": false
  });
});